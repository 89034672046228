<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 13.84"
    xml:space="preserve"
    width="24"
    height="24"
    fill="currentColor"
  >
    <path
      d="m0,12.01c0-.4.2-.9.5-1.2L10.79.52c.7-.7,1.8-.7,2.4,0l10.29,10.29c.7.7.7,1.8,0,2.4-.7.7-1.8.7-2.4,0L11.99,4.22,2.9,13.31c-.7.7-1.8.7-2.4,0C.2,12.91,0,12.51,0,12.01Z"
    />
  </svg>
</template>
